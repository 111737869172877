<template>
    <div class="pt-5">
       <h1>hello pdf</h1>
    </div>
</template>

<script>
// import pdf from 'vue-pdf'

export default {
    props: {
        file: {
        type: String,
        required: true
        },
        folder: {
        type: String,
        required: true
        },
  },
  data() {
    return {
      nightMode: false,
      pdf_name: '',
    };
  }
}
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}

.icon-back {
    cursor:pointer;
}
.icon-back:hover {
    color: gray;
}
</style>