<template>
  <div class="container mt-5 mb-5">
    <div class="table-responsive">
      <h2>Result of Suscribers</h2>

      <hr />

      <table class="table" id="logs_datatable" style="width:100%">
        <thead>
          <tr>
            <th>ID</th>
            <th>Email</th>
            <th>Date</th>
          </tr>
        </thead>

        <tbody class="logs_body">
     
        </tbody>
      </table>    
    </div>
  </div>
</template>
 
<script>
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

import info from "../../../info";

export default {
  mounted() {
    const dm =  btoa("askldjlkasjdlkjj123lkj123123");

    $('#logs_datatable').DataTable({
            responsive: true,
            "processing": true,
            "serverSide": true,
            "ajax": {
              "url": info.api_domain + "/db/fetch_data.php?fetch_type=subscribers&dm=" + dm,
              "dataSrc": function (response) {
                let data = response.data;

                  setTimeout(function(){ 
                  $('.logs_body').empty()
                      $.each(data, function(i, dt) {
                          var body = "<tr>";
                          body    += "<td>" + dt[0] + "</td>";
                          body    += "<td>" + dt[1] + "</td>";
                          body    += "<td>" + dt[2] + "</td>";
                          body    += "</tr>";
                      
                        $('.logs_body').append(body);
                      })
                  }, 1000);
        
              },
            },
            "iDisplayLength": 10
          });
  }
}
</script>