<template>
  <div :class="{ 'bg-white': !nightMode, 'bg-dark': nightMode }" class="p-st after-template">
    <div class="container py-4">
      <div
        class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <span
          class="title text-center"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
          >About me</span
        >
      </div>

      <br />

      <div class="row mt-4 text-center">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <p class="title2" v-html="info"></p>

          <br />
          <br />
          <h5 class="title2">In the Binary Sea 🌊</h5> <br />
          <p class="title2">In the daylight hours, I'm deep into the realms of software engineering. Lines of code, bug fixes, and innovative solutions are my daily bread. I thrive on the challenges of turning ideas into functional, elegant programs. From crafting intricate algorithms to building user-friendly interfaces, I find beauty in the complexity of the digital world.</p>

          <br />
          <br />
          <h5 class="title2">Making a Splash 🏊‍♂️</h5> <br />
          <p class="title2">When it's time to unplug and recharge, you'll often catch me at the local swimming pool. There's something magical about the rhythmic strokes and the weightless feel of being in the water. It's not just exercise; it's a meditative escape, a chance to reset my mind and let creativity flow in with each lap.</p>
        
          <br />
          <br />
          <h5 class="title2">Hoops and Dreams 🏀</h5> <br />
          <p class="title2">The squeak of sneakers on the court and the satisfying swish of the net - basketball has been a constant companion in my life. Whether playing a pickup game with friends or cheering for my favorite team, the sport brings a sense of camaraderie and a reminder that teamwork is key, both on the court and in the codebase.</p>
        
          <br />
          <br />
          <h5 class="title2">Pages of Wisdom 📚</h5> <br />
          <p class="title2">When I'm not in the pool or on the court, you'll find me curled up with a good book. Learning new things is a passion that extends beyond programming languages. From fiction to non-fiction, each book opens up a new world and a fresh perspective, enriching both my personal and professional life.</p>
        
          <br />
          <br />

          <h5 class="title2">A Dream Unfolding 🎹</h5> <br />
          <p class="title2">And now, a dream takes center stage. The piano a long-held desire now transforming into a tangible journey. Each key struck is a step into the world of melodies and harmonies, adding a new dimension to the symphony of my life.</p>
          <br />
          <br />
          <h5 class="title2">Join Me on this Journey 🚀</h5> <br />
          <p class="title2">Also soon I will launch /blog page and there I'll be sharing my insights into the ever-evolving tech landscape, the joy of sports, and the wonders found between the pages of a book. Whether you're a fellow coder, a sports enthusiast, or someone simply looking for a good read, I invite you to join me on this journey of bytes, dreams,strokes, hoops, and words.</p>
        

        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import info from "../../../info";

export default {
  name: "Skills",
  props: {
    nightMode: {
      type: Boolean,
    },
    info: {
      type: String
    }
  },
  data() {
    return {
      skills: info.skills,
    };
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}
.title1 {
  font-size: 24px;
  font-weight: 400;
}

.resume-t {
  text-decoration: underline;
}
.resume-t:hover {
  font-weight: 500;
}

p {
  text-align: justify;
  font-weight: 400;
}

.title2 {
  font-size: 22px;
  font-weight: 400;
  word-spacing: -1px;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
}

.fa {
  color: #8585ad;
  font-size: 40px;
  transition: all 0.5s;
}

.fas {
  color: #8585ad;
  font-size: 40px;
  /* font-weight: bold; */
  transition: all 0.5s;
}
</style>
