let domain_url  = window.location.origin;
let api_domain  = 'https://api.genti.dev';

let info        = {
  api_domain: api_domain,
  name: "Genti Lutfiu",
  about_me: "Hey there, thank you for scrolling to this corner of the web! By day, I'm a passionate software engineer immersed in the world of coding, algorithms, and all things tech. But when the screens go dark and the code takes a backseat, you'll find me indulging in my other loves - swimming, basketball, and the endless quest for knowledge through books, and finally the beginning of an unfulfilled dream which has now started, piano lessons.",
  
  logo_name: "Genti",
  flat_picture: require("./src/assets/portrait.png"),
  config: {
    use_cookies: true,
    navbar: {
      blur: false
    }
  },
  description:
    "I am passionate about building excellent web applications that improves the lives of those around me. " +
      "In all my years in this industry, I've honed my analytical thinking and collaboration skills, and I love working with a team, but also developed individually projects.",
  links: {
    linkedin: "https://www.linkedin.com/in/genti-lutfiu/",
    fb: "https://www.facebook.com/Genti.Lutfiu",
    instagram: "https://www.instagram.com/glutfiu/",
    gmail: "mailto:contact@genti.dev",
    resume: require("./src/assets/portrait.png") //to put reference
  },
  education: [
    {
      name: "University of Prizren ”Ukshin Hoti”",
      place: "",
      date: "2013 - 2016",
      degree: "BSc in Software Engineering",
      gpa: "",
      description:
        "https://www.uni-prizren.com/en/",
      skills: [
        "Software Engineering",
        "Algorithms",
        "Database patterns",
        "Web Development",
        "etc."
      ]
    },
  ],
  experience: [
    {
      name: "Dedalus HealthCare Group",
      place: "",
      date: "2023 - Present",
      position: "Software Engineer",
      description:
          "https://www.dedalus.com/global/en/",
      skills: ["HTML5", "CSS3", "Bootstrap", "JQuery",
        "Json", "JavaSript",  "VueJS", "PHP", "Java", "MariaDB", "Solr", "MongoDB", "RESTful API", "Microservices", "Docker"]
    },
    {
      name: "Travanto Ferienwohnungen GmbH",
      place: "",
      date: "2022 - 2023",
      position: "Software Engineer",
      description:
          "https://www.travanto.de/unternehmen/",
      skills: ["HTML5", "CSS3", "Bootstrap", "JQuery",
        "Json", "JavaSript",  "VueJS", "PHP", "Symfony", "MariaDB", "Solr", "MongoDB", "RESTful API", "Microservices", "Docker"]
    },
    {
      name: "Adaptech LLC",
      place: "",
      date: "2018 - 2022",
      position: "Full Stack Developer",
      description:
        "https://adaptech.me/",
      skills: ["HTML5", "CSS3", "Bootstrap", "JQuery", 
      "Json", "JavaSript", "PHP", "Codeigniter", "VueJS", "MariaDB", "RESTful API", "Microservices"]
    },
    {
      name: "PllotDev",
      place: "",
      date: "2016 – 2017",
      position: "Back-end developer",
      description:
        "",
      skills: ["HTML5", "CSS3", "Bootstrap", "JQuery", "PHP", "Laravel", "ReactJs", "MySQL"]
    },
    {
      name: "Central Election Commission",
      place: "",
      date: "Part-time",
      position: "Data Entry",
      description:
        "https://www.kqz-ks.org/an/",
      skills: ["Database", "Excel", "CMS"]
    },
  ],
  reference: [
    {
      name: "Adaptech LLC",
      position: "Artan Xhemaili",
      file_path: "./src/assets/pdfs/r1.pdf"
    },
  ],
  skills: [
    {
      title: "Language",
      info: [
        "PHP","Javascript","Java","Go"],
      icon: "fa fa-code"
    },
    {
      title: "Front-end",
      info: [
        "HTML5","CSS3","Bootstrap3"],
      icon: "fa fa-cubes"
    },
    {
      title: "Newest web tech",
      info: ["Vue.js", "React.js", "Node.js"],
      icon: "fas fa-laptop-code"
    },
    {
      title: "Databases",
      info: ["MariaDB", "MySQL", "SQL Server", "NoSQL", "MongoDB"],
      icon: "fa fa-database"
    },
    {
      title: "Operating systems",
      info: [ "Ubuntu", "Windows", "MacOS"],
      icon: "fas fa-tools"
    },
    {
      title: "Version Control",
      info: ["Git", "Bitbucket"],
      icon: "fa fa-link"
    }
  ],
  portfolio: [
    {
      id: 1,
      name: "This portfolio",
      pictures: [
        {
          img: require("./src/assets/portfolio/here/1.png")
        },
      ],
      technologies: ["HTML", "CSS", "Vue.js", "Node.js", "Express.js"],
      category: "Web App",
      date: "2022",
      github: "",
      visit: "https://www.genti.dev/",
      description: "All what you see here and more to come.."
    },
    {
      id: 2,
      name: "Dental Clinic",
      pictures: [
        {
          img: require("./src/assets/portfolio/dental/1.png")
        },
        {
          img: require("./src/assets/portfolio/dental/2.png")
        },
        {
          img: require("./src/assets/portfolio/dental/3.png")
        },
        {
          img: require("./src/assets/portfolio/dental/4.png")
        },
        {
          img: require("./src/assets/portfolio/dental/5.png")
        },
        {
          img: require("./src/assets/portfolio/dental/6.png")
        },
        {
          img: require("./src/assets/portfolio/dental/7.png")
        },
        {
          img: require("./src/assets/portfolio/dental/8.png")
        },
        {
          img: require("./src/assets/portfolio/dental/9.png")
        },
        {
          img: require("./src/assets/portfolio/dental/10.png")
        },
        {
          img: require("./src/assets/portfolio/dental/11.png")
        },
        {
          img: require("./src/assets/portfolio/dental/12.png")
        },
      ],
      technologies: ["HTML", "CSS", "JavaSript", "JQuery", "PHP(Codeigniter)", "MySQL"],
      category: "Web App",
      date: "2021",
      github: "https://dental.genti.dev/admin",
      visit: "https://dental.genti.dev/",
      description: "Making appointments, requesting appointments, viewing appointments (user side), contact, viewing the work done, managing employees, Monthly Reports, and all managed by the administrator, etc. "
    },
    {
      id: 3,
      name: "A-L Construction",
      pictures: [
        {
          img: require("./src/assets/portfolio/construct/1.png")
        },
        {
          img: require("./src/assets/portfolio/construct/2.png")
        },
        {
          img: require("./src/assets/portfolio/construct/3.png")
        },
        {
          img: require("./src/assets/portfolio/construct/4.png")
        },
        {
          img: require("./src/assets/portfolio/construct/5.png")
        }
      ],
      technologies: ["HTML", "CSS", "JavaSript", "JQuery", "PHP", "PDO"],
      category: "Web App",
      date: "2019",
      github: "https://construction.genti.dev/private/admin/",
      visit: "https://construction.genti.dev/",
      description:
      "Making appointments, contacting, viewing the work done in the construction department, as well as managing the administrator all in one place."
    },
    {
      id: 4,
      name: "PllotDev",
      pictures: [
        {
          img: require("./src/assets/portfolio/pllot/1.png")
        },
        {
          img: require("./src/assets/portfolio/pllot/2.png")
        },
        {
          img: require("./src/assets/portfolio/pllot/3.png")
        },
        {
          img: require("./src/assets/portfolio/pllot/4.png")
        },
      ],
      technologies: ["HTML", "CSS", "JavaSript", "JQuery", "PHP", "MariaDB"],
      category: "Web App",
      date: "2017",
      github:
        "",
      visit: "https://pllotdev.genti.dev/",
      description:
        " Contacting, viewing the work done inside company, as well as managing the administrator all in one place. "
    },
  ],

  portfolio_design: [
    {
      id: 5,
      name: "Reservation system",
      title: "Reservation system",
      pictures: [
        {
          img: require("./src/assets/designs/reservation/1.png"),
          title: ""
        },
        {
          img: require("./src/assets/designs/reservation/2.png"),
          title: ""
        },
        {
          img: require("./src/assets/designs/reservation/3.png"),
          title: ""
        },
        {
          img: require("./src/assets/designs/reservation/4.png"),
          title: ""
        },
        {
          img: require("./src/assets/designs/reservation/5.png"),
          title: ""
        },
        {
          img: require("./src/assets/designs/reservation/6.png"),
          title: ""
        },
        {
          img: require("./src/assets/designs/reservation/7.png"),
          title: ""
        },
      ],
      technologies: ["React.js", "PHP (Laravel)", "MySQL"],
      category: "Web App",
      github: "https://reservation.genti.dev/",
      date: "2021 - Present",
      visit: "https://reservation.genti.dev/",
      description:
        "Business and client sign-up/sign-in process, searching for businesses by name, also by filtering etc."
    },
  ],
};

export default info;
