import Vue from 'vue'
import App from './App.vue'

import AOS from 'aos'
import 'aos/dist/aos.css'
import './assets/css/style.css'
import VueParallaxJs from 'vue-parallax-js'
import VueScrollTo from 'vue-scrollto'
import VueRouter from 'vue-router'
import VTooltip from 'v-tooltip'

import Home from './components/pages/Home.vue'
import Resume from './components/pages/Resume.vue'
import Contact from './components/pages/Contact.vue'
import Portfolio from './components/pages/Portfolio.vue'

import Pdf from './components/helpers/Pdf.vue'

//admin part
import Index from './components/admin/Index.vue'

import NotFound from './components/NotFound.vue'

var VueCookie = require('vue-cookie');

Vue.use(VTooltip)
Vue.use(VueRouter)
Vue.use(VueScrollTo)
Vue.use(VueCookie);
Vue.use(VueParallaxJs)

Vue.config.productionTip = false

const routes = [
{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/resume',
    name: 'Resume',
    component: Resume
},
{
    path: '/contact',
    name: 'Contact',
    component: Contact
},
{
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
},

{
  path: '/pdf/:folder/:file',
  name: 'Pdf',
  component: Pdf,
  props: true
},


//admin part
{
  path: '/admin/private',
  name: 'Index',
  component: Index
},


{ path: '*', beforeEnter: (to, from, next) => { next('/404') } },
  {
    path: '/404',
    name: '404',
    component: NotFound
  }

]

const router = new VueRouter({
  mode:'history',
  routes // short for `routes: routes`
})

new Vue({
  created () {
    AOS.init()
  },
  router,
  render: h => h(App),
}).$mount('#app')
