<template>
  <div id="app" 
  :class="{ 'text-dark': !nightMode, 'text-light': nightMode }">
    <Navbar
    :key="$route.fullPath"
     @nightMode="switchMode" :nightMode="nightMode"/>

    <div class="parent">
        <router-view :nightMode="nightMode"></router-view>
    
        <Footer :nightMode="nightMode" />
    </div>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer";

import info from "../info";

import axios from 'axios'
// import $ from "jquery";

export default {
  name: "App",
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      nightMode: false,
      config: info.config,
      navOpened: false
    };
  },
  created() {
    if (this.config.use_cookies) {
      this.nightMode = this.$cookie.get("nightMode") === "true" ? true : false;
    }
  },
  methods: {
    switchMode(mode) {
      if (this.config.use_cookies) {
        this.$cookie.set("nightMode", mode);
      }
      this.nightMode = mode;
    },
    // closeNav(event) {
    //   // console.log(event)
    //   // $('.navbar-collapse:not(.navbar-toggler)').removeClass('show');
    // },
  },
   mounted: function() {
      if(this.$route.path !== '/admin/private') {
         const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        }

        const data = {
          dm: btoa("ajkljfklsjlkfjklask;fjd;iweruiwueriwer@#@")
        }

          axios.post(info.api_domain + "/db/logs.php", data, headers);
      }
    }
};
</script>