<template>
  <div
    class="py-4 p-st pt-5"
    :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container">
      <div class="text-center">
        <div
          class="my-3"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <select
            @change="showResult"
            name="result_select"
            v-model="result_select"
            class="pinput"
            :class="{
              pgray: !nightMode,
              'pgray-dark': nightMode,
              'text-light': nightMode,
            }"
            style="transition-delay: 0.4s"
          >
            <option value="">Please select what to show!</option>
            <option value="logs">Logs</option>
            <option value="contacts">Contacts</option>
            <option value="subsribers">Subscribers</option>
          </select>
        </div>
      </div>

      <Snackbar
        :showSnackbar="showSnackbar"
        @close="closeSnackbar"
        :snackbarMessage="snackbarMessage"
        :snackbarColor="snackbarColor"
      />

      <Logs v-if="result_type==='logs'" :nightMode="nightMode" ></Logs>
      <Contacts v-if="result_type==='contacts'" :nightMode="nightMode" ></Contacts>
      <Subscribers v-if="result_type==='subsribers'" :nightMode="nightMode" ></Subscribers>
    </div>
  </div>
</template>

<script>
import Snackbar from "../helpers/Snackbar";

import Logs from './Logs.vue';
import Contacts from './Contacts.vue';
import Subscribers from './Subscribers.vue';

export default {
  name: "Search",
  components: {
    Snackbar,
    Logs,
    Contacts,
    Subscribers,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      result_select: "",
      result_type: "",
      showSnackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
    };
  },
  methods: {
    closeSnackbar(val) {
      if (!val) {
        setTimeout(() => {
          this.showSnackbar = val;
        }, 1000);
      }
    },
    showResult() {
      if (!this.result_select) {
        this.result_type = "";

        this.showSnackbar = true;
        this.snackbarMessage = "Please select type!";
        this.snackbarColor = "#64808E";
      } else {
        this.result_type = this.result_select
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}
.title1 {
  font-size: 24px;
  font-weight: 400;
}

.title2 {
  font-size: 20px;
  font-weight: 400;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
}

.pinput {
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 10px;
  width: 50%;
  transition: all 1s;
  background-color: #b3b3cc;
}

.btn {
  border-color: #8585ad;
  color: #8585ad;
  width: 50%;
}

.btn:hover {
  background-color: #8585ad;
  border-color: #8585ad;
  color: white;
}

.btn:focus {
  background-color: #8585ad;
  border-color: #8585ad;
  color: white;
}

.pgray-dark {
  background-color: #3c4148 !important;
}

@media screen and (max-width: 1000px) {
  .pinput {
    width: 90%;
  }
  .pinput {
    width: 90%;
  }

  .btn {
    width: 90%;
  }
}
</style>
