var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-light fixed-top p-st",class:{
      'bg-light': !_vm.nightMode,
      'navbar-blur': _vm.navbarConfig.blur,
      'bg-dark2': _vm.nightMode,
    }},[_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":{ name: 'Home' }}},[_c('Logo',{attrs:{"nightMode":_vm.nightMode}})],1),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"},on:{"click":_vm.toggleNav}},[_c('span',{staticStyle:{"color":"gray","font-size":"23px"}},[(_vm.navOpened === false)?_c('i',{staticClass:"fas fa-bars"}):_vm._e(),(_vm.navOpened)?_c('i',{staticClass:"fas fa-times"}):_vm._e()])]),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[(this.$route.path !== '/')?_c('li',{staticClass:"nav-item mx-2"},[_c('router-link',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"to":{ name: 'Home' }}},[_vm._v(" Home ")])],1):_vm._e(),_c('li',{staticClass:"nav-item mx-2"},[_c('router-link',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"to":{ name: 'Resume' }}},[_vm._v(" Resume ")])],1),_c('li',{staticClass:"nav-item mx-2"},[_c('router-link',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"to":{ name: 'Portfolio' }}},[_vm._v(" Portfolio ")])],1),_c('li',{staticClass:"nav-item mx-2"},[_c('router-link',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"to":{ name: 'Contact' }}},[_vm._v(" Contact ")])],1),_c('li',{staticClass:"nav-item ml-2"},[_c('a',{staticClass:"nav-link",class:{ 'text-light': _vm.nightMode },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchMode.apply(null, arguments)}}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.nightMode ? 'Light Mode' : 'Night Mode'),expression:"nightMode ? 'Light Mode' : 'Night Mode'",modifiers:{"bottom":true}}],class:{
                  'fas fa-sun': _vm.nightMode,
                  'far fa-moon': !_vm.nightMode,
                }})])])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }