<template>
  <div class="bg-secondary footer-row">
    <div class="container py-3">
      <div class="row pt-1 align-items-center">
        <div
          class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pbelow"
          style="color: white;"
        >
          <span>© {{year}} Copyright: Genti Lutfiu</span>
          <br />
        </div>

        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <i @click="open('gmail')"
              v-tooltip.bottom="'Email'" class="icon-btn mx-3 fa fa-envelope"></i>

              <i class="icon-btn mx-3 fab fa-linkedin" @click="open('linkedin')"
              v-tooltip.bottom="'LinkedIn'"></i>
         
              <i @click="open('fb')" v-tooltip.bottom="'Facebook'"
                  class="icon-btn mx-3 fab fa-facebook">
              </i>
      
              <i @click="open('instagram')"
              v-tooltip.bottom="'Instagram'" 
              class="icon-btn mx-3 fab fa-instagram"></i>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import info from "../../info";

export default {
  name: "Footer",
  data() {
    return {
      linkedin: info.links.linkedin,
      fb: info.links.fb,
      instagram: info.links.instagram,
      gmail: info.links.gmail,
      year: new Date().getFullYear()
    };
  },
  methods: {
    open(link) {
      switch (link) {
        case "linkedin":
          window.open(this.linkedin, "_blank");
          break;
        case "fb":
          window.open(this.fb, "_blank");
          break;
        case "instagram":
          window.open(this.instagram, "_blank");
          break;
        case "gmail":
          window.open(this.gmail, "_blank");
          break;
      }
    },
  },
};
</script>

<style scoped>
span {
  font-weight: 500;
}

.btn {
  border-color: white;
  color: white;
}

.btn:hover {
  background-color: white;
  border-color: white;
  color: gray;
}

.btn:focus {
  background-color: white;
  border-color: white;
  color: gray;
}

@media screen and (max-width: 580px) {
  .pbelow { 
    padding-bottom: 20px;
    text-align: center;
  }
}

.footer-row {
  background-color: #8585ad !important;
  position: fixed;
  width: 100%;
  margin-top: 25px;
  bottom: 0px; 
  z-index: 500;
}

.icon-btn {
  cursor:pointer;
  border-color: #8585ad;
  color: whitesmoke;
}

</style>
