var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-st after-template",class:{
    'bg-light': !_vm.nightMode,
    'bg-dark2': _vm.nightMode,
    'text-light': _vm.nightMode,
  }},[_c('div',{staticClass:"container mb-5 pb-5"},[_c('div',{staticClass:"text-center",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"1000"}},[_c('span',{staticClass:"title text-center",class:{ pgray: !_vm.nightMode, 'text-light': _vm.nightMode }},[_vm._v("Contact")])]),_c('br'),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mb-3",attrs:{"data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"pinput",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.2s"},attrs:{"type":"text","name":"user_name","placeholder":"Name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_c('div',{staticClass:"my-3",attrs:{"data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"pinput",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.4s"},attrs:{"type":"email","name":"user_email","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_c('div',{staticClass:"my-3",attrs:{"data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subject),expression:"subject"}],staticClass:"pinput",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.4s"},attrs:{"type":"text","name":"subject","placeholder":"Subject"},domProps:{"value":(_vm.subject)},on:{"input":function($event){if($event.target.composing)return;_vm.subject=$event.target.value}}})]),_c('div',{staticClass:"my-3",attrs:{"data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"pinput",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.6s"},attrs:{"name":"message","placeholder":"Message","rows":"4"},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}})]),_c('button',{staticClass:"mt-1 btn mb-3",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"1000","data-aos-offset":"50"},on:{"click":function($event){$event.preventDefault();return _vm.sendContact.apply(null, arguments)}}},[_vm._v(" Send ")]),_c('br'),_vm._m(0)]),_c('Snackbar',{attrs:{"showSnackbar":_vm.showSnackbar,"snackbarMessage":_vm.snackbarMessage,"snackbarColor":_vm.snackbarColor},on:{"close":_vm.closeSnackbar}})],1),_c('Subscribe',{attrs:{"nightMode":_vm.nightMode}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"option-contact"},[_c('b',[_vm._v("..or freely send an message in following email: "),_c('a',{staticClass:"hyperlink",attrs:{"href":"mailto:contact@genti.dev"}},[_vm._v("contact@genti.dev")])])])
}]

export { render, staticRenderFns }