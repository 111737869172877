<template>
  <div
    class="after-template py-4 p-st pt-5"
    :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container">
      <div class="text-center">
        <div
          class="my-3"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
        >
           <h2 class="mb-5">Ops nothing found!
           </h2>

           <h3 class="mb-5">If you have on mind something which you like to see please tell me to add 
                    <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
           </h3>

                <button @click="$router.go(-1)"
                        class="btn">
                    <i class="fa fa-arrow-left"></i>
                </button>

                <br />
                <br />

               
               <router-link 
                  class="btn"
                  :to="{ name: 'Home' }">
                   Home
               </router-link>
              <router-view :nightMode="nightMode"></router-view>
            
        </div>
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    nightMode: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.btn {
  border-color: #8585ad;
  color: #8585ad;
  width: 50%;
}

.btn:hover {
  background-color: #8585ad;
  border-color: #8585ad;
  color: white;
}

.btn:focus {
  background-color: #8585ad;
  border-color: #8585ad;
  color: white;
}
</style>