<template>
  <div
  class="after-template"
   :class="{ 'bg-white': !nightMode, 'bg-dark': nightMode }"
  >
    <div
      class="container"
      data-aos="fade"
      data-aos-once="true"
      data-aos-duration="1000"
    >
      <div class="row align-items-center">
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 text-center align-items-center phone-part">
           <div class="text-center align-items-center icon-part">
            <span class="icon-social">
              <i @click="open('gmail')" v-tooltip.bottom="'Email'" class="icon-btn mx-3 mail-icon fa fa-envelope fa-5x"></i>
            </span>

              <i class="icon-btn mx-3 fab fa-linkedin fa-2x" @click="open('linkedin')" v-tooltip.bottom="'LinkedIn'"></i>
         
              <i @click="open('fb')" v-tooltip.bottom="'Facebook'" class="icon-btn mx-3 fab fa-facebook fa-2x"></i>
      
              <i @click="open('instagram')" v-tooltip.bottom="'Instagram'" class="icon-btn mx-3 fab fa-instagram fa-2x"></i>
          </div>
          
          <div>
            <img class="mb-5 pb-4 phone" :style="{'max-width': '100%'}" :src="picture" />
          </div>
        </div>

        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">
          <span
            class="home-title"
            :class="{ pgray: !nightMode, 'text-light': nightMode }"
            >Hi, I'm Genti.<br />
            Thank you for visiting my site, will be my pleasure if you take a look around, to know a little bit more about me!</span
          >
        </div>
      </div>

       <hr :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"/>
    </div>

<!--    <Skills :nightMode="nightMode"></Skills>-->
    <About :nightMode="nightMode" :info="about_me"></About>
  </div>
</template>

<script>
import info from "../../../info"

import Wave from "../helpers/Wave"
import About from './About.vue'

export default {
  name: "Home",
  components: {
    Wave,
    About
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      picture: info.flat_picture,
      description: info.description,
      name: info.name,
      linkedin: info.links.linkedin,
      fb: info.links.fb,
      instagram: info.links.instagram,
      gmail: info.links.gmail,
      about_me: info.about_me
    };
  },
  methods: {
    open(link) {
      switch (link) {
        case "linkedin":
          window.open(this.linkedin, "_blank");
          break;
        case "fb":
          window.open(this.fb, "_blank");
          break;
        case "instagram":
          window.open(this.instagram, "_blank");
          break;
        case "gmail":
          window.open(this.gmail, "_blank");
          break;
      }
    },
  },
};
</script>

<style scoped>

.mail-icon {
  font-size: 32px !important;
}

.home-title {
  font-size: 32px;
  font-weight: 500;
}

img {
  max-width: 800px;
  max-height: 500px;
  margin-top: 80px;
  transform: rotateY(180deg);
}

.fa {
  font-size: 15px;
}

.icon-btn {
  cursor:pointer;
  border-color: #8585ad;
  color: #8585ad;
}

.icon-btn:hover {
  /* background-color: #8585ad; */
  border-radius: 5px;
  color: cadetblue;
}

.icon-btn:focus {
  color: white;
}

.icon-btn:focus {
  outline: none !important;
}

p {
  text-align: justify;
  font-weight: 400;
}

.phone {
  position: relative;
  margin-top: -150px !important;
}

.icon-part {
  position: relative;
  margin-top: 10rem;
  z-index: 1000;
}

/* LEAVES */
</style>
