<template>
  <div
    class="p-st after-template"
    :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container">
      <div
        class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <span
          class="title text-center"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
          >Resume</span
        >
      </div>
      
       <!-- <hr :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"/> -->

      <div class="row">
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">
          <Timeline :data="education" :nightMode="nightMode" />
        </div>
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">
          <Timeline :data="experience" :nightMode="nightMode" />
        </div>
      </div>
    </div>

    <div class="row p-0 m-0">
      <Skills :nightMode="nightMode"></Skills>
    </div>
  </div>
</template>

<script>
import Timeline from "../helpers/Timeline";
import Skills from "./Skills";
import info from "../../../info";

export default {
  name: "Resume",
  components: {
    Timeline,
    Skills
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      education: {
        title: "Education",
        data: info.education,
      },
      experience: {
        title: "Work History",
        data: info.experience,
      },
    };
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}
</style>