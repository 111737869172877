import { render, staticRenderFns } from "./DesignModal.vue?vue&type=template&id=8177c8fa&scoped=true"
import script from "./DesignModal.vue?vue&type=script&lang=js"
export * from "./DesignModal.vue?vue&type=script&lang=js"
import style0 from "./DesignModal.vue?vue&type=style&index=0&id=8177c8fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8177c8fa",
  null
  
)

export default component.exports