<template>
  <div
    class="p-st after-template"
    :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container  mb-5 pb-5">
      <div
        class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <span
          class="title text-center"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
          >Contact</span
        >
      </div>
       
      <br />
      <div class="text-center">
        <div
          class="mb-3"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <input
            type="text"
            name="user_name"
            v-model="name"
            placeholder="Name"
            class="pinput"
            :class="{
              pgray: !nightMode,
              'pgray-dark': nightMode,
              'text-light': nightMode,
            }"
            style="transition-delay: 0.2s"
          />
        </div>

        <div
          class="my-3"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <input
            type="email"
            name="user_email"
            v-model="email"
            placeholder="Email"
            class="pinput"
            :class="{
              pgray: !nightMode,
              'pgray-dark': nightMode,
              'text-light': nightMode,
            }"
            style="transition-delay: 0.4s"
          />
        </div>

         <div
          class="my-3"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <input
            type="text"
            name="subject"
            v-model="subject"
            placeholder="Subject"
            class="pinput"
            :class="{
              pgray: !nightMode,
              'pgray-dark': nightMode,
              'text-light': nightMode,
            }"
            style="transition-delay: 0.4s"
          />
        </div>

        <div
          class="my-3"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <textarea
            name="message"
            v-model="text"
            placeholder="Message"
            class="pinput"
            rows="4"
            :class="{
              pgray: !nightMode,
              'pgray-dark': nightMode,
              'text-light': nightMode,
            }"
            style="transition-delay: 0.6s"
          ></textarea>
        </div>

        <button
          @click.prevent="sendContact"
          class="mt-1 btn mb-3"
          data-aos="fade"
          data-aos-once="true"
          data-aos-duration="1000"
          data-aos-offset="50"
        >
          Send
        </button>

        <br />

        <small class="option-contact"><b>..or freely send an message in following email: <a href="mailto:contact@genti.dev" class="hyperlink">contact@genti.dev</a></b></small>
      </div>

      <Snackbar
        :showSnackbar="showSnackbar"
        @close="closeSnackbar"
        :snackbarMessage="snackbarMessage"
        :snackbarColor="snackbarColor"
      />
    </div>

    <Subscribe :nightMode="nightMode"></Subscribe>
  </div>
</template>

<script>
import axios from 'axios'

import Snackbar from "../helpers/Snackbar";
import Subscribe from './Subscribe.vue';

import info from "../../../info";

export default {
  name: "Contact",
  components: {
    Snackbar,
    Subscribe,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      email: "",
      name: "",
      subject: "",
      text: "",
      showSnackbar: false,
      snackbarMessage: "",
      snackbarColor: "",
    };
  },
  methods: {
    closeSnackbar(val) {
      if (!val) {
        setTimeout(() => {
          this.showSnackbar = val;
        }, 1000);
      }
    },
    sendContact() {
      if (!this.email || !this.name || !this.text || !this.subject) {
        this.showSnackbar = true;
        this.snackbarMessage = "Please fill all the fields";
        this.snackbarColor = "#64808E";
      } else {
        const data = {
          contact_name: this.name,
          contact_email: this.email,
          contact_subject: this.subject,
          contact_message: this.text,
          dm: btoa("genti.tech")
        }

        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        }

        axios.post(info.api_domain + "/db/send_message.php", data, headers)
          .then(response => {
            this.showSnackbar = true;

            if(response.data.status == 200) {
              this.email = '';
              this.name = '';
              this.text = '';
              this.subject = '';
            }

            this.snackbarMessage = response.data.message;
            this.snackbarColor = "#64808E";
          })
          .catch(error => {
            this.showSnackbar = true;
            this.snackbarMessage = "Something went wrong, Please try again!";
            this.snackbarColor = "#64808E";
        });
      }
    },
  },
};
</script>

<style scoped>
.hyperlink {
  text-decoration: underline;
  color: #8585ad !important;
}
.option-contact {
  font-size: 11px !important;
}

.title {
  font-size: 30px;
  font-weight: 500;
}
.title1 {
  font-size: 24px;
  font-weight: 400;
}

.title2 {
  font-size: 20px;
  font-weight: 400;
}

.title3 {
  font-size: 16px;
  font-weight: 400;
}

.pinput {
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 10px;
  width: 50%;
  transition: all 1s;
  background-color: #b3b3cc;
}

.btn {
  border-color: #8585ad;
  color: #8585ad;
  width: 50%;
}

.btn:hover {
  background-color: #8585ad;
  border-color: #8585ad;
  color: white;
}

.btn:focus {
  background-color: #8585ad;
  border-color: #8585ad;
  color: white;
}

.pgray-dark {
  background-color: #3c4148 !important;
}

@media screen and (max-width: 1000px) {
  .pinput {
    width: 90%;
  }
  .pinput {
    width: 90%;
  }

  .btn {
    width: 90%;
  }
}
</style>
